
.mapview {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ededed;
    justify-content: start;
    align-items: flex-start;
  
    &__map {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &.open-sidenav {
      height: calc(100% - 80vh);

      @include DS {
        width: calc(100% - 472px);
        height: 100%;
      }
    }
  }