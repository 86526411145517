.map-switch {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  gap: 0.5rem;
  z-index: 1;
  cursor: pointer;

  img {
    width: 56px; 
    height: 56px; 

    @include DS {
      width: 4.5rem; 
      height: 4.5rem; 
    }
  }
}
