.search {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;

  button {
    cursor: pointer;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    z-index: 1;

    svg {
      & + svg {
        margin-left: 0.25rem;
      }
    }
  }

  form {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    label {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
        background-color: #ffffff;
        width: 1.5rem;
        height: 1.5rem;
        padding: 10px 15px;

        @include DS {
          padding: 0.75rem 1rem;
        }
      }

      input {
        appearance: none;
        all: unset;
        transition: 0.2s;
        font-weight: 400;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #454554;
        width: 0px;
        caret-color: transparent;
        height: 2.75rem;


      @include DS {
        height: 3rem;
      }

        & + ul + button {
          display: none;
          transition: 0.2s;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  &.active {
    input {
      background-color: #ffffff;
      z-index: 1;
      caret-color: #000000;
      width: calc(100vw - 140px);

      @include DS {
        width: 20rem;
      }

      &::placeholder {
        visibility: visible;
      }

      & + ul + button {
        display: contents;
      }
    }
  }

  &__list {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 3.25rem;
    background-color: #ffffff;
    margin-top: 0;
    padding: 0;
    max-height: 80vh;
    overflow: auto;

    &.active {
      overflow: scroll;
    }

    button {
      position: relative;
      display: flex;
      justify-content: flex-start;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: #fff;
      text-decoration: none;
      width: 100%;
      text-align: inherit;
      color: #1d3051;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;

      & > svg {
        height: 2rem !important;
        width: 2rem !important;
        margin: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
      }
    }
  }

  &-investment {
    padding: 0.9375rem 0.9375rem 0;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    span {
      width: 100%;
      display: block;
      text-align: start;
      color: #1d3051;
      font-size: 1rem;
      font-style: normal;
      line-height: 1rem;

      &:nth-child(1) {
        font-weight: bold;
      }
    }

    img {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  hr {
    border-color: #F2F2F2;
    width: calc(100% - 30px);
    margin: 10px auto;
    border-style: solid;
  }
}
