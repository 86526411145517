.gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: white;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    h2 {
      margin: 0;
      color: #1d3051;
      font-size: 24px;
      font-weight: 600;
    }

    button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__carousel {
    @include DS {
      padding: 20px 0;
      position: relative;
      height: calc(100% - 30px - 40px - 24px);
    }

    .carousel-root,
    .carousel.carousel-slider {
      height: 100%;
    }

    .carousel {
      .slider-wrapper {
        height: 100%;
      }

      .slider {
        gap: 8px;
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    button {
      position: absolute;
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      border: 1px solid #f2f2f2;
      background: #fff;
      gap: 8px;
      top: calc(50% - 25px);
      z-index: 3;
      cursor: pointer;

      &.prev {
        left: 16px;
        transform: rotate(180deg);
      }
      &.next {
        right: 16px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__carousel__mobile {
    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
}
