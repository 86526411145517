.filter {
  position: absolute;
  left: 1rem;
  top: 1rem;
  background-color: white;
  z-index: 1;
  padding: 0.375rem 0.5rem;
  min-width: 16rem;

  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    min-height: 2rem;

    span {
      width: 100%;
      display: block;
      text-align: start;
      color: #1d3051;
      font-size: 1rem;
      font-style: normal;
      line-height: 1rem;

      &:nth-child(1) {
        font-weight: bold;
      }
    }

    .icon {
      transition: 0.25s all;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &.open {
        transform: rotate(180deg);
      }
    }
  }

  hr {
    margin: 0.375rem 0;
    border-color: #D9D9D9;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 0.5rem 0;
  }
}
