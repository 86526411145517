.checkbox {
  &-wrapper {
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: .5rem;
      position: relative;
      cursor: pointer;
    }
    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid #1D3051;
      outline: none;
      cursor: pointer;
      flex: none;
      width: 1rem;
      height: 1rem;
      margin: 0;
      border-radius: 2px;


      &.checked {
        background-color: #1D3051;
        position: relative;

        &::before {
          content: "";
          font-size: 1rem;
          color: #fff;
          position: absolute;
          right: 1px;
          top: -5px;
        }
      }
    }

    span {
      position: absolute;
      left: 0;
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      img {
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        opacity: 0.5;
        filter: saturate(0);
      }
  
      p {
        color:  #1D3051;
        margin: 0;
      }
  
      &.checked {
        img {
          opacity: 1;
          filter: saturate(1);
        }
        p {
          font-weight: 600;
        }
      }
    }
  }
}
