// Mixin to manage responsive breakpoints
// Media query manager
/*
> 0         MS - Mobile Small
> 378px     ML - Mobile Large
> 962px    DS - Desktop Small
*/

// Breakpoints mixin
//
// Basic mixin of breakpoints. These can be used to configure 
// designs at different screen widths.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  ms: 0,
  ml: 378px,
  ds: 962px,
);


@mixin MS {
    @media (min-width: map-get($grid-breakpoints, "ms")) {
        @content;
    }
}

@mixin ML {
    @media (min-width: map-get($grid-breakpoints, "ml")) {
        @content;
    }
}

@mixin DS {
    @media (min-width: map-get($grid-breakpoints, "ds")) {
        @content;
    }
}
