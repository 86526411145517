// Inactive
.sidenav {
  z-index: 4;
  background: #fff;
  overflow-x: hidden;
  transition: all 0.5s;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: -100%;

  @include DS {
    position: fixed;
    right: -473px;
    width: 472px;
    top: 0;
  }

  &__content {
    display: flex;
    padding: 1rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    
    @include DS {
      padding-top: 1rem;
    }

    &-top {
      display: flex;
      gap: 0.5rem;
      align-items: flex-start;
      align-self: stretch;
      position: sticky;
      top: 0px;
      background: white;
      z-index: 2;
      padding-top: 1rem;
      padding-bottom: 1.5rem;

      @include DS {
        padding-top: 0;
        top: 16px;
      }

      h3 {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        color: #1d3051;
        font-size: 1.5rem;
        font-weight: 600;
        flex: 1 0 0;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &-middle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.25rem;

      &__images {
        display: grid;
        position: relative;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        cursor: pointer;
        width: 100%;

        img {
          object-fit: cover;
          &:nth-child(1) {
            grid-row: 2 span;
            min-width: 224px;
            height: 160px;
            width: 100%;

            @include DS {
              width: 280px;
              height: 200px;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 100%;
            height: 76px;

            @include DS {
              height: 96px;
            }
          }
        }
        span {
          position: absolute;
          bottom: 0;
          left: 0;
          display: inline-flex;
          background: #1d3051;
          padding: 5px 10px;
          align-items: center;
          gap: 8px;
          color: white;
          font-size: 0.875rem;
          font-weight: 700;
        }
      }

      &__details {
        width: 100%;
      }

      &__desc {
        p {
          color: #1d3051;
          font-size: 16px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid #f2f2f2;
    gap: 32px;

    &.last {
      border-bottom: none;
    }

    span {
      color: #1d3051;
      font-size: 16px;
      font-weight: 400;
    }

    .circle {
      width: 2px;
      height: 2px;
      background-color: #d9d9d9;
    }

    &-left {
      white-space: nowrap;
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      gap: 2px;
      flex-wrap: wrap;

      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
      }
    }
  }
}

// Active
.open-sidenav + .sidenav {
  transition: all 0.5s;
  bottom: -20vh;
  @include DS {
    right: 0;
  }
}
