.warning {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1d3051;
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    g {
        fill: white;
    }
  }

  span {
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
}
